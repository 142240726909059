import { CustomRaisedButton } from "../../../../../../Components/CustomComponents/CustomButtons";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import styles from "./OfferDetails.module.css";
import CustomDatePicker from "../../../../../../Components/CustomComponents/CustomDatePicker";
import { LectureTimeField } from "../../../../../../Store/Appearance/RequiredFields/FieldNames";
import CustomTextField from "../../../../../../Components/CustomComponents/CustomTextField";
import CustomCheckbox from "../../../../../../Components/CustomComponents/CustomCheckbox";
import { useSelector } from "react-redux";
import { getFullOfferedLectures } from "../../../../../../Store/InitialData/Selectors";
import { toDateFormat } from "../../../../../../Util/TimeUtil";
import CopyOfferLinkButton from "./CopyOfferLinkButton";
import { OrderLecture } from "../../../../../../generated/Api";

interface OfferDetailsProps {
  lectureTime: OrderLecture;
  onChangeField: (key: LectureTimeField) => (value: string | boolean) => void;
  paymentConditions?: string;
}

export default function OfferDetails(props: OfferDetailsProps) {
  const lectureTime = props.lectureTime;
  const offeredLectures = useSelector(getFullOfferedLectures);

  const resetTemplate = () => {
    if (!lectureTime.topic) {
      return;
    }
    const offeredLecture = offeredLectures[lectureTime.topic];
    if (!offeredLecture) {
      return;
    }
    props.onChangeField(LectureTimeField.offerDate)(toDateFormat(new Date()));
    props.onChangeField(LectureTimeField.subject)(offeredLecture.subject || "");
    props.onChangeField(LectureTimeField.content)(offeredLecture.content || "");
    props.onChangeField(LectureTimeField.offerCost)(offeredLecture.cost || "");
    props.onChangeField(LectureTimeField.paymentConditionInfo)(
      convertPaymentConditionToOfferInfo(props.paymentConditions),
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.firstRow}>
          <CustomRaisedButton
            style={{ marginInlineEnd: 20 }}
            onClick={resetTemplate}
            label={"מילוי מתבנית"}
            icon={<RotateLeftIcon />}
            variant="outlined"
          />

          <CustomDatePicker
            field={LectureTimeField.offerDate}
            valueObject={lectureTime}
            onChange={props.onChangeField(LectureTimeField.offerDate)}
          />

          <div className={styles.subjectField}>
            <CustomTextField
              field={LectureTimeField.subject}
              valueObject={lectureTime}
              onChange={props.onChangeField(LectureTimeField.subject)}
              fullWidth
            />
          </div>
        </div>

        <div className={styles.contentRow}>
          <CustomTextField
            field={LectureTimeField.content}
            valueObject={lectureTime}
            onChange={props.onChangeField(LectureTimeField.content)}
            fullWidth
          />
        </div>

        <div className={styles.row}>
          <CustomTextField
            field={LectureTimeField.offerCost}
            valueObject={lectureTime}
            onChange={props.onChangeField(LectureTimeField.offerCost)}
            fullWidth
          />
          <CustomTextField
            field={LectureTimeField.paymentConditionInfo}
            valueObject={lectureTime}
            onChange={props.onChangeField(
              LectureTimeField.paymentConditionInfo,
            )}
            fullWidth
          />
          <div className={styles.noTravetCostCheckbox}>
            <CustomCheckbox
              field={LectureTimeField.noTravelCost}
              valueObject={lectureTime}
              onChange={props.onChangeField(LectureTimeField.noTravelCost)}
            />
          </div>

          <div className={styles.copyLinkButton}>
            <CopyOfferLinkButton
              orderId={lectureTime.orderId}
              lectureTime={lectureTime}
              returnNullIfDisabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function convertPaymentConditionToOfferInfo(conditions?: string) {
  switch (conditions) {
    case "תשלום מיידי":
      return "תשלום מיידי.";
    case "שוטף + 0":
      return "ראשון לחודש שלאחר משלוח חשבונית עסקה.";
    case "שוטף + 30":
    default:
      return "שוטף + 30 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 45":
      return "שוטף + 45 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 60":
      return "שוטף + 60 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 75":
      return "שוטף + 70 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 30 + 7 לחודש":
      return "שוטף + 37 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 30 + 22 לחודש":
      return "שוטף + 52 ממועד משלוח חשבונית עסקה.";
  }
}
