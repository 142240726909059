import AutoComplete, { ISuggestion } from "../../Components/AutoComplete";
import { useEffect, useState } from "react";
import {
  CustomIconButton,
  CustomRaisedButton,
} from "../../Components/CustomComponents/CustomButtons";
import _ from "lodash";
import { useSelector } from "react-redux";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import {
  getOrdersIndex,
  getOrganizationsIndex,
} from "../../Store/InitialData/Selectors";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import styles from "./Admin.module.scss";
import CustomTable from "../../Components/Table/CustomTable";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Organization, OrganizationContact } from "../../generated/Api";

export function MoveOrderToOrganization() {
  const organizationsIndex = useSelector(getOrganizationsIndex);
  const ordersIndex = useSelector(getOrdersIndex);
  const { callApi, isLoading } = useVioletApi();

  const [searchedOrder, setSearchedOrder] = useState("");
  const [orderId, setOrderId] = useState<string | undefined>();
  const [searchedOrganization, setSearchedOrganization] = useState("");
  const [organizationId, setOrganizationId] = useState<string | undefined>();
  const [organization, setOrganization] = useState({} as Organization);
  const [contactId, setContactId] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const orderSuggestions = ordersIndex.map<ISuggestion>((i) => ({
    key: i.id + "",
    label: i.organizationName + " - " + i.id,
  }));
  const organizationSuggestions = organizationsIndex.map<ISuggestion>((i) => ({
    key: i.id + "",
    label: i.organizationName,
  }));

  useEffect(() => {
    if (!organizationId) {
      return;
    }
    callApi((api) =>
      api.organizations
        .getOrganization({
          organizationId,
        })
        .then((res) => {
          if (res.data?.organization) {
            setOrganization(res.data.organization);
          } else {
            setOrganization({} as Organization);
          }
        }),
    );
  }, [organizationId, callApi]);

  const moveOrder = () => {
    setSuccess(false);
    setError("");
    if (!orderId || !organizationId || !contactId) {
      setError("קלט שגוי");
      return;
    }
    callApi((api) =>
      api.orders
        .moveOrderToOrganization({
          orderId,
          organizationId,
          contactId,
        })
        .then((res) => {
          setSuccess(res.data.success);
          setError(res.data.errorMessage || "");
          if (res.data.success) {
            setSearchedOrder("");
            setOrderId("");
            setSearchedOrganization("");
            setOrganizationId("");
            setOrganization({} as Organization);
            setContactId("");
          }
        }),
    );
  };

  const contacts = (organization?.contacts || []).filter((x) => !x.hidden);
  const onContactSelected = (contact: OrganizationContact) => {
    setContactId(contact.id);
  };

  return (
    <CustomSection title="העברת הזמנה לארגון אחר">
      <div className={styles.column}>
        <div className={styles.row}>
          <AutoComplete
            title="מספר הזמנה"
            onSuggestionSelected={(suggestion) => setOrderId(suggestion?.key)}
            suggestions={orderSuggestions}
            textFieldValue={searchedOrder}
            onTextChange={setSearchedOrder}
            width={300}
          />
          {orderId && (
            <AutoComplete
              title="ארגון"
              onSuggestionSelected={(suggestion) =>
                setOrganizationId(suggestion?.key)
              }
              suggestions={organizationSuggestions}
              textFieldValue={searchedOrganization}
              onTextChange={setSearchedOrganization}
              width={300}
            />
          )}
        </div>
        <div>
          {!organizationId || _.isEmpty(contacts) ? (
            isLoading ? (
              <div>טוען...</div>
            ) : (
              <div>לא קיימים אנשי קשר לארגון זה</div>
            )
          ) : (
            <CustomTable
              tableHeaders={[
                { pick: "בחר" },
                { firstName: "שם פרטי" },
                { lastName: "שם משפחה" },
                { job: "תפקיד" },
                { phone1: "טלפון" },
                { email: "דואר אלקטרוני" },
              ]}
              elements={contacts}
              onEditButton={onContactSelected}
              onPickButton={onContactSelected}
              renderCell={(headerKey, element) => {
                if (headerKey !== "pick") {
                  return null;
                }
                return (
                  <CustomIconButton
                    style={{ height: 40, width: 40 }}
                    onClick={() => onContactSelected(element)}
                  >
                    {element.id === contactId ? (
                      <RadioButtonCheckedIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                  </CustomIconButton>
                );
              }}
            />
          )}
        </div>

        {!!contactId && (
          <CustomRaisedButton
            label="העבר הזמנה"
            onClick={moveOrder}
            variant={"contained"}
          />
        )}
        <div className={styles.message}>
          <span className={styles.error}>{error}</span>
          {success && "ההזמנה הועברה בהצלחה"}
        </div>
      </div>
    </CustomSection>
  );
}
