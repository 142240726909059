import CustomDialog from "../../../../Components/CustomComponents/CustomDialog";
import CustomTable from "../../../../Components/Table/CustomTable";
import * as _ from "lodash";
import {
  CustomFlatButton,
  CustomIconButton,
} from "../../../../Components/CustomComponents/CustomButtons";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Organization, OrganizationContact } from "../../../../generated/Api";

interface ImportContactsDialogProps {
  dialogOpen: boolean;
  onRequestClose: () => void;
  organization?: Organization;
  onContactSelected: (contactId: string) => void;
}

export default function ImportContactsDialog(props: ImportContactsDialogProps) {
  const onContactSelected = (contact: OrganizationContact) => {
    props.onRequestClose();
    props.onContactSelected(contact.id);
  };

  const contacts = (props.organization?.contacts || []).filter(
    (x) => !x.hidden,
  );

  return (
    <CustomDialog
      open={props.dialogOpen}
      title="יבא איש קשר"
      onRequestClose={props.onRequestClose}
      actions={[
        <CustomFlatButton
          key="ok"
          label="סגור"
          onClick={props.onRequestClose}
        />,
      ]}
    >
      {_.isEmpty(contacts) ? (
        <div>לא קיימים אנשי קשר לארגון זה</div>
      ) : (
        <CustomTable
          tableHeaders={[
            { pick: "בחר" },
            { firstName: "שם פרטי" },
            { lastName: "שם משפחה" },
            { job: "תפקיד" },
            { phone1: "טלפון" },
            { email: "דואר אלקטרוני" },
          ]}
          elements={contacts}
          onEditButton={onContactSelected}
          onPickButton={onContactSelected}
          renderCell={(headerKey, element) => {
            if (headerKey !== "pick") {
              return null;
            }
            return (
              <CustomIconButton
                style={{ height: 40, width: 40 }}
                onClick={() => onContactSelected(element)}
              >
                <RadioButtonUncheckedIcon />
              </CustomIconButton>
            );
          }}
        />
      )}
    </CustomDialog>
  );
}
