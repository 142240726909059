import LoginPage from "./LoginPage";
import { useFirebaseLogin } from "../firebase/Authentication";

export default function Auth(props: { children: React.ReactNode }) {
  const { loggedId, signInWithGoogle } = useFirebaseLogin();

  if (loggedId) {
    return <>{props.children}</>;
  } else {
    return <LoginPage login={signInWithGoogle} />;
  }
}
