import AutoComplete from "../../Components/AutoComplete";
import { useEffect, useState } from "react";
import { toSuggestions } from "../../Components/AutoComplete";
import TextField from "../../Components/TextField";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Screen from "../../Components/Screen";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import SaveIcon from "@mui/icons-material/Save";
import { updateOfferedLecture } from "../../Store/InitialData/Actions";
import { getFullOfferedLectures } from "../../Store/InitialData/Selectors";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import { OfferedLecture } from "../../generated/Api";
import CustomRadioGroup from "../../Components/CustomComponents/CustomRadioGroup";

const emptyTemplate: OfferedLecture = {
  topic: "",
  subject: "",
  content: "",
  cost: "",
};

export default function OfferTemplatesPage() {
  const [topic, setTopic] = useState("");
  const [template, setTemplate] = useState(emptyTemplate);
  const [editExistingTemplate, setEditExistingTemplate] = useState(true);
  const offerTemplates = useSelector(getFullOfferedLectures);
  const dispatch = useDispatch();
  const { callApi } = useVioletApi();

  const updateTemplate = async () => {
    const request = {
      topic: topic,
      subject: template.subject,
      content: template.content,
      cost: template.cost,
    };
    callApi((api) =>
      api.offeredLectures.updateOfferedLecture(request).then((res) => {
        if (!res?.data) return;
        dispatch(updateOfferedLecture(res.data, topic));
      }),
    );
  };

  useEffect(() => {
    const topicToShow = topic.trim();
    if (!offerTemplates || !offerTemplates.hasOwnProperty(topicToShow)) {
      setTemplate(emptyTemplate);
    } else {
      setTemplate(offerTemplates[topicToShow]);
    }
  }, [topic, setTemplate, offerTemplates]);

  if (!offerTemplates) {
    return null;
  }

  return (
    <Screen>
      <CustomSection title="תבניות הצעות מחיר">
        <CustomRadioGroup
          options={[true, false]}
          title="עריכת תבנית:"
          value={editExistingTemplate}
          getLabel={(b) => (b ? "קיימת" : "חדשה")}
          onSelected={setEditExistingTemplate}
        />
        {editExistingTemplate ? (
          <AutoComplete
            title="נושא"
            textFieldValue={topic}
            onTextChange={setTopic}
            onSuggestionSelected={(suggestion) =>
              setTopic(suggestion?.label || "")
            }
            suggestions={toSuggestions(_.keys(offerTemplates).sort())}
            fullWidth
          />
        ) : (
          <TextField title="נושא" value={topic} onChange={setTopic} fullWidth />
        )}

        <TextField
          title="כותרת"
          value={template.subject}
          onChange={(subject) =>
            setTemplate({
              ...template,
              subject,
            })
          }
          fullWidth
        />

        <TextField
          title="תוכן"
          value={template.content}
          onChange={(content) =>
            setTemplate({
              ...template,
              content,
            })
          }
          fullWidth
        />

        <TextField
          title="עלות"
          value={template.cost}
          onChange={(cost) =>
            setTemplate({
              ...template,
              cost,
            })
          }
          fullWidth
        />

        <CustomRaisedButton
          label="שמור"
          onClick={updateTemplate}
          variant="contained"
          icon={<SaveIcon />}
        />
      </CustomSection>
    </Screen>
  );
}
