import { useEffect, useState } from "react";
import BiPageContent from "./BiPageContent";
import { useNavigate } from "react-router";
import { getOrderPath } from "../Path";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import { FilterStatusEnum, ReferralWayOrder } from "../../generated/Api";

export default function BiPage() {
  const navigate = useNavigate();
  const thisYear = new Date().getFullYear();
  const { callApi } = useVioletApi();

  const [filterStatus, setFilterStatus] = useState<FilterStatusEnum>();
  const [startDate, setStartDate] = useState(thisYear + "-01-01");
  const [endDate, setEndDate] = useState(thisYear + "-12-31");

  const [orders, setOrders] = useState<ReferralWayOrder[]>([]);

  useEffect(() => {
    callApi((api) =>
      api.orders
        .getReferralWays({ startDate, endDate, filterStatus })
        .then((res) => {
          if (res.data) {
            setOrders(res.data);
          }
        }),
    );
  }, [startDate, endDate, filterStatus, callApi]);

  const referralWayCounters: { [referralWay: string]: number } = {};
  orders.forEach((order) => {
    const referralWay = order.referralWay;
    if (!referralWay) return;
    if (!(referralWay in referralWayCounters)) {
      referralWayCounters[referralWay] = 0;
    }
    referralWayCounters[referralWay]++;
  });

  return (
    <BiPageContent
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      filterStatus={filterStatus}
      setFilterStatus={setFilterStatus}
      ordersMatchingFilters={orders}
      onTableRowEdit={(summary) => navigate(getOrderPath(summary.orderId))}
      referralWayCounters={referralWayCounters}
    />
  );
}
