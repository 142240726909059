import { useEffect, useState } from "react";
import CustomDialog from "../../../Components/CustomComponents/CustomDialog";
import { IOption } from "../../../Components/CustomComponents/IOption";
import SelectField from "../../../Components/SelectField";
import { Size } from "../../../Util/Constants/Size";
import { toPrintableDateFormat } from "../../../Util/TimeUtil";
import { useVioletApi } from "../../../Auth/useFetchWithMsal";
import CustomTable from "../../../Components/Table/CustomTable";
import { CustomRaisedButton } from "../../../Components/CustomComponents/CustomButtons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  LectureParticipantEmail,
  PublicCourse,
  PublicCourseLecture,
} from "../../../generated/Api";

export default function CopyEmailsDialog(props: {
  open: boolean;
  onRequestClose: () => void;
  publicCourse?: PublicCourse;
}) {
  const { callApi } = useVioletApi();
  const [selectedLectureId, setSelectedLectureId] = useState<
    string | undefined
  >();
  const [res, setRes] = useState<LectureParticipantEmail[]>([]);

  useEffect(() => {
    if (!selectedLectureId) {
      return;
    }
    callApi((api) =>
      api.publicCourses
        .getLectureParticipantsEmails({
          lectureId: selectedLectureId,
        })
        .then((res) => {
          setRes(res.data || []);
        }),
    );
  }, [selectedLectureId, callApi]);

  if (!props.publicCourse) {
    return null;
  }

  const options = props.publicCourse.publicCourseLectures.map<IOption>(
    (lecture) => ({
      key: lecture.id,
      label: getLecutreLabel(lecture),
    }),
  );

  const onLectureSelected = (lectureId: string) =>
    setSelectedLectureId(lectureId);

  const onCopy = () => {
    const text = res
      .filter((x) => x.email)
      .map((x) => x.email!.trim())
      .join(",");
    navigator.clipboard.writeText(text).then(props.onRequestClose);
  };

  return (
    <CustomDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      title="העתקת כתובות דואר אלקטרוני"
      actions={[
        <CustomRaisedButton
          key="close"
          label="ביטול"
          onClick={props.onRequestClose}
        />,
        <CustomRaisedButton
          key="delete"
          label="העתק"
          onClick={onCopy}
          icon={<ContentCopyIcon />}
          variant="contained"
          disabled={res.length === 0}
        />,
      ]}
    >
      <SelectField
        label="בחר יום"
        onChange={onLectureSelected}
        options={options}
        size={Size.X2L}
        value={
          selectedLectureId === undefined ? undefined : selectedLectureId + ""
        }
      />

      {res.length > 0 && (
        <CustomTable
          elements={res}
          tableHeaders={[{ name: "שם" }, { email: "דואר אלקטרוני" }]}
          onEditButton={() => {}}
        />
      )}
    </CustomDialog>
  );
}

function getLecutreLabel(lecture: PublicCourseLecture | undefined) {
  if (!lecture?.date) {
    return "";
  }
  return toPrintableDateFormat(lecture.date) + " - " + lecture.topic;
}
