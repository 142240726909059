import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import DatePicker from "../../Components/DatePicker";
import SelectField from "../../Components/SelectField";
import { Size } from "../../Util/Constants/Size";
import ReferralWayChart from "./ReferralWayChart";
import { IStringObject } from "../../Interfaces/IStringObject";
import { getStatusOptions } from "../../Interfaces/Status";
import { FilterStatusEnum, ReferralWayOrder } from "../../generated/Api";

export interface BiPageContentProps {
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date: string) => void;
  filterStatus?: FilterStatusEnum;
  setFilterStatus: (status: FilterStatusEnum) => void;
  ordersMatchingFilters: ReferralWayOrder[];
  referralWayCounters: { [referralWay: string]: number };
  onTableRowEdit: (orderSummary: ReferralWayOrder) => void;
}

export default function BiPageContent(props: BiPageContentProps) {
  const filters = (
    <div
      style={{
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <div>
        <DatePicker
          title="תאריך התחלה"
          value={props.startDate}
          onChange={props.setStartDate}
        />

        <DatePicker
          title="תאריך סיום"
          value={props.endDate}
          onChange={props.setEndDate}
        />

        <SelectField
          label="סנן לפי סטאטוס"
          value={props.filterStatus}
          onChange={(value: string) =>
            props.setFilterStatus(value as FilterStatusEnum)
          }
          options={getStatusOptions()}
          size={Size.XL}
          allowEmpty
        />
      </div>

      <ReferralWayChart referralWayCounters={props.referralWayCounters} />
    </div>
  );

  return (
    <CustomPaperTable
      title="איך הגיעו אלינו"
      elements={props.ordersMatchingFilters}
      onEditButton={props.onTableRowEdit}
      beforeTable={filters}
      tableHeaders={
        [
          { orderId: "מספר הזמנה" },
          { organizationName: "שם הארגון" },
          { status: "סטאטוס" },
          { referralWay: "איך הגיע אלינו" },
          { referralWayDetails: "פרטי הגעה" },
        ] as IStringObject[]
      }
    />
  );
}
