import CustomPaperTable from "../../../../Components/Table/CustomPaperTable";
import { getOrderPath } from "../../../Path";
import { useNavigate } from "react-router";
import _ from "lodash";
import { IStringObject } from "../../../../Interfaces/IStringObject";
import {
  PublicCourseLectureSummary,
  PublicCourseParticipantSummary,
} from "../../../../generated/Api";

const CHECKED = "✅";

export default function CourseParticipantsTableContainer(props: {
  participantsSummary: PublicCourseParticipantSummary[];
  lecturesSummary: PublicCourseLectureSummary[];
  approved: boolean;
}) {
  const navigate = useNavigate();

  const elements = props.participantsSummary
    .filter((x) => x.approved === props.approved)
    .map((participantSummary) => {
      const res: any = {
        ...participantSummary,
      };

      getAttendingLecturesName(
        participantSummary.attendedLectureIds,
        props.lecturesSummary,
      ).forEach((lectureName) => {
        res[lectureName] = CHECKED;
      });

      return res;
    });

  if (elements.length === 0) {
    return null;
  }
  const participantCountByTopic: { [topic: string]: number } = {};
  // Iterate over all lectures
  props.lecturesSummary.map((lectureSummary) => {
    // Go over all rows in table
    var lectureParticipantsCount = _.filter(
      elements,
      (row) => row[lectureSummary.topic] === CHECKED,
    ).length;

    return (participantCountByTopic[lectureSummary.topic] =
      lectureParticipantsCount);
  });

  const tableHeaders: IStringObject[] = [
    { firstName: "שם פרטי" },
    { lastName: "שם משפחה" },
    { organizationName: "ארגון" },
    { proformaInvoiceNumber: "חשבונית עסקה" },
    { certificate: "תעודה" },
    { orderStatus: "סטאטוס" },
  ];

  const distinctLectureNames = _.uniq(
    props.lecturesSummary.map((lecture) => lecture.topic),
  );

  distinctLectureNames.forEach((topic) => {
    tableHeaders.push({
      [topic]: `${topic} (${participantCountByTopic[topic]})`,
    });
  });

  return (
    <CustomPaperTable
      title={props.approved ? "משתתפים מאושרים" : "משתתפים פוטנציאלים"}
      elements={elements}
      tableHeaders={tableHeaders}
      onEditButton={(summary) => {
        navigate(getOrderPath(summary.orderId));
      }}
    />
  );

  function getAttendingLecturesName(
    attendingLectureIds: string[],
    lecturesSummary: PublicCourseLectureSummary[],
  ): string[] {
    return _.uniq(
      attendingLectureIds.map(
        (lectureId) =>
          lecturesSummary.filter((x) => x.lectureId === lectureId)[0].topic,
      ),
    );
  }
}
