import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Screen from "../../Components/Screen";
import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import { IStringObject } from "../../Interfaces/IStringObject";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import TextField from "../../Components/TextField";
import SideMenu from "../../Components/SideMenu";
import { getLectureTitle } from "./PublicCourseAttendancePage";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import SaveIcon from "@mui/icons-material/Save";
import {
  OrganizationField,
  PublicCourseParticipantField,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { Size } from "../../Util/Constants/Size";
import {
  CourseAttendanceLecture,
  CourseAttendanceParticipant,
} from "../../generated/Api";

export default function PublicCourseTestScoresPage() {
  const { courseId } = useParams<{ courseId?: string }>();
  const dispatch = useDispatch();
  const { callApi, isLoading } = useVioletApi();

  const [courseName, setCourseName] = useState("");
  const [lectures, setLectures] = useState<CourseAttendanceLecture[]>([]);
  const [participantsAttendace, setParticipantsAttendace] = useState<
    CourseAttendanceParticipant[]
  >([]);
  const [dataToSave, setDataToSave] = useState<{
    [participantAndLectureIdKey: string]: {
      publicCourseParticipantId: string;
      publicCourseLectureId: string;
      score: string;
    };
  }>({});

  useEffect(() => {
    if (!courseId) return;

    callApi((api) =>
      api.publicCourses
        .getPublicCourseParticipants({
          publicCourseId: courseId,
        })
        .then((res) => {
          if (!res.data) {
            setCourseName("");
            setLectures([]);
            setParticipantsAttendace([]);
            setDataToSave({});
            return;
          }
          setCourseName(res.data.courseName);
          setLectures(res.data.lectures);
          setParticipantsAttendace(res.data.participantsAttendace);
        }),
    );
  }, [courseId, dispatch, callApi]);

  const tableHeaders: IStringObject[] = [
    {
      [PublicCourseParticipantField.firstName]: getFieldLabel(
        PublicCourseParticipantField.firstName,
      ),
    },
    {
      [PublicCourseParticipantField.lastName]: getFieldLabel(
        PublicCourseParticipantField.lastName,
      ),
    },
    { organizationName: getFieldLabel(OrganizationField.name) },
  ];
  lectures.forEach((lecture) =>
    tableHeaders.push({
      [lecture.lectureId]: getLectureTitle(lecture),
    }),
  );

  const onScoreSet = async (
    participantId: string,
    lectureId: string,
    newScore: string,
  ) => {
    // Update UI
    setParticipantsAttendace((attendance) => {
      const res = [...attendance];
      const courseAttendanceParticipant = res.filter(
        (x) => x.participantId === participantId,
      )[0];
      courseAttendanceParticipant.scores[lectureId] = newScore;
      return res;
    });

    // Update DataToSave
    const participantAndLectureIdKey = participantId + "#" + lectureId;
    const newDataToSave = { ...dataToSave };
    newDataToSave[participantAndLectureIdKey] = {
      publicCourseParticipantId: participantId,
      publicCourseLectureId: lectureId,
      score: newScore,
    };
    setDataToSave(newDataToSave);
  };

  const onSave = async () => {
    const request = Object.values(dataToSave);
    callApi((api) =>
      api.publicCourses.setScores(request).then((res) => {
        setDataToSave({});
      }),
    );
  };

  const sideMenu = (
    <SideMenu title={"דף ציונים בקורס - " + courseName}>
      <CustomRaisedButton
        onClick={onSave}
        label="שמור"
        icon={<SaveIcon />}
        variant="contained"
        fullWidth
        disabled={Object.keys(dataToSave).length === 0}
      />
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu} isLoading={lectures.length === 0 && isLoading}>
      <CustomPaperTable
        title={"דף ציונים בקורס - " + courseName}
        elements={participantsAttendace}
        tableHeaders={tableHeaders}
        onEditButton={() => {}}
        renderCell={(headerKey, participantAttendace) => {
          switch (headerKey) {
            case PublicCourseParticipantField.firstName:
            case PublicCourseParticipantField.lastName:
            case "organizationName":
              return null;
          }

          const lectureId = headerKey;

          const lectureAttendence = participantAttendace.scores[lectureId];
          if (lectureAttendence === undefined) {
            // Should not show a score field for this lecture (decided by the server).
            return <div />;
          }

          const score = participantAttendace.scores[lectureId];

          return (
            <TextField
              title="ציון"
              value={score}
              onChange={(newScore) =>
                onScoreSet(
                  participantAttendace.participantId,
                  lectureId,
                  newScore,
                )
              }
              size={Size.S}
            />
          );
        }}
      />
    </Screen>
  );
}
