import { useState, useCallback } from "react";
import { VioletApi } from "../generated/Api";
import { getAuthToken } from "../firebase/Authentication";

const isProd = process.env.NODE_ENV === "production";
const DEV_BASE_URL = "http://127.0.0.1:5001/violet-dev/us-central1/v7/auth";
const PROD_BASE_URL = "https://func-violet-prod.azurewebsites.net/api";
export const BASE_URL = isProd ? PROD_BASE_URL : DEV_BASE_URL;

export const useVioletApi = () => {
  const [isLoading, setIsLoading] = useState(false);

  const callApi = async (call: (api: VioletApi<unknown>) => Promise<void>) => {
    const token = await getAuthToken();
    console.log("Token", token);

    if (!token) {
      return null;
    }

    const bearer = `Bearer ${token}`;
    const api = new VioletApi({
      baseUrl: BASE_URL,

      baseApiParams: {
        headers: {
          Authorization: bearer,
        },
      },
    });
    setIsLoading(true);
    return call(api)
      .then(() => setIsLoading(false))
      .catch((e) => {
        alert(
          "התרחשה שגיאה בשרת. יש לנסות לטעון את הדף מחדש. אם עדיין לא עובד יש לדווח לירון מה הפעולה שניסית לעשות ונכשלה\n" +
            e,
        );
        setIsLoading(false);
        throw e;
      });
  };

  return {
    isLoading,
    callApi: useCallback(callApi, []), // to avoid infinite calls when inside a useEffect;
  };
};
