import CustomPaper from "../../Components/CustomComponents/CustomPaper";
import { EntityType } from "../../Util/Constants/EntityType";
import styles from "./SearchResultCard.module.css";
import ArticleIcon from "@mui/icons-material/Article";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PersonIcon from "@mui/icons-material/Person";
import classnames from "classnames";
import { useNavigate } from "react-router";
import { getOrderPath, getOrganizationPath } from "../Path";
import { cutIfLong } from "../../Util/StringUtil";
import { toPrintableDateFormat } from "../../Util/TimeUtil";

const ICON_SIZE = 80;

interface SearchResultCardPropsBase {
  id: string;
  organizationName?: string;
}

export default function SearchResultCard(
  props: OrderSearchResult | OrganizationSearchResult | ParticipantSearchResult,
) {
  const navigate = useNavigate();
  let icon;
  let Content;
  switch (props.entityType) {
    case EntityType.organization:
      Content = <OrganizationCardContent {...props} />;
      icon = (
        <CorporateFareIcon
          style={{ fontSize: ICON_SIZE }}
          className={classnames(styles.icon, styles.organizationIcon)}
        />
      );
      break;

    case EntityType.order:
      Content = <OrderCardContent {...props} />;
      icon = (
        <ArticleIcon
          style={{ fontSize: ICON_SIZE }}
          className={classnames(styles.icon, styles.orderIcon)}
        />
      );
      break;

    case EntityType.publicCourseParticipant:
      Content = <ParticipantCardContent {...props} />;
      icon = (
        <PersonIcon
          style={{ fontSize: ICON_SIZE }}
          className={classnames(styles.icon, styles.participantIcon)}
        />
      );
      break;
  }

  const onClick = () => {
    switch (props.entityType) {
      case EntityType.organization:
        navigate(getOrganizationPath(props.id));
        break;

      case EntityType.order:
      case EntityType.publicCourseParticipant:
        navigate(getOrderPath(props.id));
        break;
    }
  };

  return (
    <div className={styles.container}>
      <CustomPaper className={styles.card} onClick={onClick}>
        <div>{icon}</div>
        {Content}
      </CustomPaper>
    </div>
  );
}

export interface OrganizationSearchResult extends SearchResultCardPropsBase {
  entityType: EntityType.organization;
  companyId?: string;
}

function OrganizationCardContent(props: OrganizationSearchResult) {
  return (
    <div className={styles.content}>
      <div className={styles.title}>{props.organizationName}</div>
      <div>מספר חברה: {props.companyId}</div>
    </div>
  );
}

export interface OrderSearchResult extends SearchResultCardPropsBase {
  entityType: EntityType.order;
  firstLectureDate?: string;
  status: string;
}

function OrderCardContent(props: OrderSearchResult) {
  return (
    <div className={styles.content}>
      <div className={styles.title}>הזמנה: {props.id}</div>
      <div>ארגון: {ellipsis(props.organizationName, 25)}</div>
      <div>סטאטוס: {props.status}</div>
      {props.firstLectureDate && (
        <div>תאריך הרצאה: {toPrintableDateFormat(props.firstLectureDate)}</div>
      )}
    </div>
  );
}

export interface ParticipantSearchResult extends SearchResultCardPropsBase {
  entityType: EntityType.publicCourseParticipant;
  name: string;
  courseName: string;
}

function ParticipantCardContent(props: ParticipantSearchResult) {
  return (
    <div className={styles.content}>
      <div className={styles.title}>שם: {props.name}</div>
      <div>ארגון: {ellipsis(props.organizationName)}</div>
      <div>קורס: {ellipsis(props.courseName)}</div>
    </div>
  );
}

function ellipsis(str?: string, length = 30) {
  if (!str) {
    return "";
  }
  return cutIfLong(str, length);
}
