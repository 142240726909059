import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import styles from "./ContactCard.module.scss";
import CallIcon from "@mui/icons-material/Call";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EmailIcon from "@mui/icons-material/Email";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import _ from "lodash";
import { OnOrderChangeFunction } from "../../OrderPage";
import { CustomFlatButton } from "../../../../Components/CustomComponents/CustomButtons";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import ImportContactsDialog from "./ImportContactDialog";
import { useState } from "react";
import {
  Field,
  OrderField,
  getFieldLabel,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import SetContactDialog from "./SetContactDialog";
import ContactIconField from "../../../../Components/CardIconField";
import { Organization, OrganizationContact } from "../../../../generated/Api";

export default function ContactCard(props: {
  idField: OrderField.contactId | OrderField.financialContactId;
  contactId?: string;
  organization: Organization;
  setOrganization: (newOrg: Organization) => void;
  onOrderChange: OnOrderChangeFunction;
  requiredFields: Field[];
}) {
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const hasValue = !!props.contactId;
  const error = !hasValue && _.includes(props.requiredFields, props.idField);

  const contact = hasValue
    ? undefined
    : _.find(props.organization.contacts, (c) => c.id === props.contactId);

  const options = (
    <div>
      {props.organization.contacts.length > 0 && (
        <CustomFlatButton
          label="בחר מרשימה"
          onClick={() => setImportDialogOpen(true)}
          icon={<ChecklistRtlIcon />}
          style={{ marginInlineEnd: 10 }}
        />
      )}
      {contact ? (
        <CustomFlatButton
          label="ערוך איש קשר"
          onClick={() => setCreateDialogOpen(true)}
          icon={<EditIcon />}
        />
      ) : (
        <CustomFlatButton
          label="צור איש קשר"
          onClick={() => setCreateDialogOpen(true)}
          icon={<PersonAddIcon />}
        />
      )}
    </div>
  );

  return (
    <>
      <CustomSection
        title={getFieldLabel(props.idField)}
        className={error ? styles.hasMissingFields : undefined}
        options={options}
      >
        <ContactInformation
          contact={contact}
          organization={props.organization}
        />
      </CustomSection>
      <ImportContactsDialog
        dialogOpen={importDialogOpen}
        organization={props.organization}
        onRequestClose={() => setImportDialogOpen(false)}
        onContactSelected={props.onOrderChange(props.idField)}
      />
      <SetContactDialog
        key={"setContactDialog." + contact?.id}
        dialogOpen={createDialogOpen}
        organization={props.organization}
        setOrganization={props.setOrganization}
        contact={contact}
        onRequestClose={() => setCreateDialogOpen(false)}
        onContactCreated={props.onOrderChange(props.idField)}
      />
    </>
  );
}

function ContactInformation(props: {
  contact?: OrganizationContact;
  organization: Organization;
}) {
  if (!props.contact) {
    return null;
  }
  let name = "";

  if (props.contact.firstName) {
    name += props.contact.firstName;
  }
  if (props.contact.lastName) {
    name += " " + props.contact.lastName;
  }
  if (props.contact.job) {
    name += " - " + props.contact.job;
  }

  return (
    <div>
      <ContactIconField value={name} icon={<PermContactCalendarIcon />} />
      <ContactIconField
        value={props.contact.phone1}
        href={(phone) => "tel:" + phone}
        icon={<CallIcon />}
      />
      <ContactIconField
        value={props.contact.phoneExtension}
        icon={<SupportAgentIcon />}
      />
      <ContactIconField
        value={props.contact.phone2}
        href={(phone) => "tel:" + phone}
        icon={<AddIcCallIcon />}
      />
      <ContactIconField
        value={props.contact.email}
        href={(email) => "mailto:" + email}
        icon={<EmailIcon />}
      />
    </div>
  );
}
