import OrganizationsOrdersTable from "./OrganizationsOrdersTable";
import Screen from "../../Components/Screen";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import SideMenu from "../../Components/SideMenu";
import OrganizationSaveButton from "./OrganizationSaveButton";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import { Path } from "../Path";
import { updateObject } from "../../Util/ObjectUpdater";
import { OrganizationField } from "../../Store/Appearance/RequiredFields/FieldNames";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import OrganizationsContactsTable from "./OrganizationsContactsTable";
import EditableOrganizationSection from "../OrderPage/Sections/OrganizationSection/EditableOrganizationSection";
import { Organization, OrganizationOrderSummary } from "../../generated/Api";

export const EXISTING_ORGANIZATION_REQUIRED_FIELDS = [
  OrganizationField.name,
  OrganizationField.referralWay,
];

export default function OrganizationPage() {
  const { callApi, isLoading } = useVioletApi();
  const { organizationId } = useParams<{ organizationId?: string }>();
  const [organization, setOrganization] = useState<Organization>(
    {} as Organization,
  );
  const [isDuplicateOrganizationId, setIsDuplicateOrganizationId] =
    useState(false);
  const [organizationOrdersSummary, setOrganizationOrdersSummary] = useState<
    OrganizationOrderSummary[]
  >([]);

  useEffect(() => {
    if (!organizationId) {
      return;
    }
    callApi((api) =>
      api.organizations
        .getOrganization({
          organizationId,
        })
        .then((res) => {
          if (res.data.organization) {
            setOrganization(res.data.organization);
            setOrganizationOrdersSummary(res.data.organizationOrdersSummary);
            setIsDuplicateOrganizationId(res.data.isDuplicateOrganizationId!);
            return;
          }

          setOrganization({} as Organization);
          setOrganizationOrdersSummary([]);
          setIsDuplicateOrganizationId(false);
        }),
    );
  }, [organizationId, callApi]);

  const sideMenu = (
    <SideMenu title="עדכן פרטי ארגון">
      <OrganizationSaveButton organization={organization} />
      <NewOrderButton organization={organization} />
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu} isLoading={isLoading}>
      <EditableOrganizationSection
        organization={organization}
        requiredFields={EXISTING_ORGANIZATION_REQUIRED_FIELDS}
        isDuplicateOrganizationId={isDuplicateOrganizationId}
        onOrganizationChange={(field) => (value) => {
          setOrganization(
            updateObject(organization, {
              [field]: value,
            }),
          );
        }}
      />

      <OrganizationsOrdersTable
        organizationOrdersSummary={organizationOrdersSummary}
      />

      <OrganizationsContactsTable
        organization={organization}
        setOrganization={setOrganization}
      />
    </Screen>
  );
}

function NewOrderButton(props: { organization: Organization }) {
  const navigate = useNavigate();

  return (
    <>
      <br />
      <br />
      <CustomRaisedButton
        label="הזמנה חדשה"
        onClick={() =>
          navigate(Path.NewOrderForOrg + "/" + props.organization.id)
        }
        variant={"outlined"}
        fullWidth
      />
    </>
  );
}
