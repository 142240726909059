import { useContext, useState } from "react";
import {
  CustomFlatButton,
  CustomIconButton,
  CustomRaisedButton,
} from "../../../../../Components/CustomComponents/CustomButtons";
import AddIcon from "@mui/icons-material/Add";
import MobileViewContext from "../../../../../Navigation/MobileViewContext";
import CustomPaperTable from "../../../../../Components/Table/CustomPaperTable";
import _ from "lodash";
import CustomDialog from "../../../../../Components/CustomComponents/CustomDialog";
import {
  PublicCourseParticipantField,
  getFieldLabel,
} from "../../../../../Store/Appearance/RequiredFields/FieldNames";
import SinglePublicCourseParticipant from "./SinglePublicCourseParticipant";
import { moneyFormat } from "../../../../../Util/StringUtil";
import { getRequiredFieldsByStatus } from "../../../RequiredFields/RequiredFieldsByStatus";
import { useNavigate } from "react-router";
import { getPublicCourseSummaryPath } from "../../../../Path";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import LoopIcon from "@mui/icons-material/Loop";
import CustomTable from "../../../../../Components/Table/CustomTable";
import { useSelector } from "react-redux";
import { getPublicCoursesIndex } from "../../../../../Store/InitialData/Selectors";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  OrderStatusEnum,
  PublicCourse,
  PublicCourseAttendance,
  PublicCourseParticipant,
} from "../../../../../generated/Api";

export default function PublicCourseParticipantsTable(props: {
  publicCourse: PublicCourse;
  publicCourseParticipants: PublicCourseParticipant[];
  onChangePublicCourse: (courseId: string) => void;
  orderStatus: OrderStatusEnum;
  setPublicCourseParticipants: React.Dispatch<
    React.SetStateAction<PublicCourseParticipant[]>
  >;
}) {
  const navigate = useNavigate();
  const publicCoursesIndex = useSelector(getPublicCoursesIndex);

  let requiredFields = getRequiredFieldsByStatus(
    props.orderStatus,
  ).publicCourseParticipant;

  const [mobile] = useContext(MobileViewContext);
  const [editingParticipantIndex, setEditingParticipantIndex] = useState(-1);
  const [changeCourseDialogOpen, setChangeCourseDialogOpen] = useState(false);
  const participants = (props.publicCourseParticipants || []).map(
    (participant, index) => ({
      ...participant,
      days: participant.lecturesAttending?.length || "",
      participantCost: moneyFormat(participant.participantCost),
      index,
    }),
  );

  const addParticipant = () => {
    const lengthBefore = props.publicCourseParticipants.length;
    props.setPublicCourseParticipants((participants) => [
      ...participants,
      { lecturesAttending: [] as any } as PublicCourseParticipant,
    ]);
    setEditingParticipantIndex(lengthBefore);
  };

  const onDelete = (rowIndex: number) => {
    props.setPublicCourseParticipants((participants) =>
      participants.filter((_, index) => index !== rowIndex),
    );
  };

  const onChangeField =
    (key: PublicCourseParticipantField) =>
    (value: string | boolean | PublicCourseAttendance[] | undefined) => {
      const participants = _.clone(props.publicCourseParticipants);
      const participant = participants[editingParticipantIndex];
      // @ts-ignore
      participant[key] = value;
      props.setPublicCourseParticipants(participants);
    };

  return (
    <>
      <CustomPaperTable
        title={"משתתפים בקורס ציבורי - " + props.publicCourse.courseName}
        sectionOptions={
          <div>
            <CustomFlatButton
              onClick={() => setChangeCourseDialogOpen(true)}
              label="החלף קורס"
              icon={<LoopIcon />}
              style={{ marginInlineEnd: 15 }}
            />
            <CustomFlatButton
              onClick={() =>
                navigate(getPublicCourseSummaryPath(props.publicCourse.id))
              }
              label="מעבר לקורס"
              icon={<KeyboardDoubleArrowLeftIcon />}
            />
          </div>
        }
        beforeTable={
          mobile ? null : (
            <div>
              <CustomRaisedButton
                label="הוסף משתתף חדש"
                onClick={addParticipant}
                icon={<AddIcon />}
                variant="outlined"
              />
            </div>
          )
        }
        tableHeaders={[
          { firstName: "שם פרטי" },
          { lastName: "שם משפחה" },
          { phone: "טלפון" },
          { email: 'דוא"ל' },
          { days: "ימים" },
          {
            desiredLectures: getFieldLabel(
              PublicCourseParticipantField.desiredLectures,
            ),
          },
          { participantCost: "מחיר השתתפות" },
          { delete: "מחק" },
        ]}
        elements={participants}
        onEditButton={(participant) =>
          !mobile && setEditingParticipantIndex(participant.index)
        }
        onDeleteButton={onDelete}
        requiredFields={requiredFields}
      />
      <CustomDialog
        title="עריכת משתתף"
        open={editingParticipantIndex >= 0}
        onRequestClose={() => setEditingParticipantIndex(-1)}
      >
        {editingParticipantIndex >= 0 && (
          <SinglePublicCourseParticipant
            participantIndex={editingParticipantIndex}
            participant={
              props.publicCourseParticipants[editingParticipantIndex]
            }
            publicCourse={props.publicCourse}
            onChangeField={onChangeField}
            requiredFields={requiredFields}
          />
        )}
      </CustomDialog>
      <CustomDialog
        title="החלפת קורס"
        open={changeCourseDialogOpen}
        onRequestClose={() => setChangeCourseDialogOpen(false)}
      >
        <CustomTable
          elements={publicCoursesIndex}
          tableHeaders={[
            { pick: "בחר" },
            { id: "מזהה" },
            { courseName: "שם הקורס" },
          ]}
          onEditButton={(courseIndex) => {
            props.onChangePublicCourse(courseIndex.id);
            setChangeCourseDialogOpen(false);
          }}
          renderCell={(headerKey, element) => {
            if (headerKey !== "pick") {
              return null;
            }
            return (
              <CustomIconButton
                style={{ height: 40, width: 40 }}
                onClick={() => {
                  props.onChangePublicCourse(element.id);
                  setChangeCourseDialogOpen(false);
                }}
              >
                <RadioButtonUncheckedIcon />
              </CustomIconButton>
            );
          }}
        />
      </CustomDialog>
    </>
  );
}
