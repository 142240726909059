import {
  signInWithPopup,
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";

const firebaseProductionConfig = {
  apiKey: "AIzaSyBYLZaVfwMoWhCBzvhO8qJjC-CzqRceR0c",
  authDomain: "violet-36bed.firebaseapp.com",
  databaseURL: "https://violet-36bed.firebaseio.com",
  projectId: "violet-36bed",
  storageBucket: "violet-36bed.appspot.com",
  messagingSenderId: "259015014878",
};

const firebaseDevelopmentConfig = {
  apiKey: "AIzaSyB0hDCH-PoE-nAXVoP4Ba6gNYAfMDufajE",
  authDomain: "violet-dev.firebaseapp.com",
  databaseURL: "https://violet-dev.firebaseio.com",
  projectId: "violet-dev",
  storageBucket: "violet-dev.appspot.com",
  messagingSenderId: "97871190058",
};

const firebaseConfig =
  process.env.NODE_ENV === "production"
    ? firebaseProductionConfig
    : firebaseDevelopmentConfig;

export const useFirebaseLogin = () => {
  const [loggedId, setLoggedIn] = useState(false);
  const [loginError, setLoginerror] = useState("");

  useEffect(() => {
    initializeApp(firebaseConfig);
    onAuthStateChanged(getAuth(), (user) => {
      setLoggedIn(!!user);
    });
  }, []);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(getAuth(), provider).catch(function (error) {
      setLoginerror(error.message);
      console.error(error);
    });
  };

  return {
    loggedId,
    loginError,
    signInWithGoogle,
  };
};

export const signOut = () => {
  getAuth().signOut();
};

export const userName = () => {
  console.log("called userName");
  return getAuth().currentUser?.displayName;
};

export const getAuthToken = async () => getAuth().currentUser?.getIdToken();
