import { useState } from "react";
import styles from "./LoginPage.module.css";
import { CustomRaisedButton } from "../Components/CustomComponents/CustomButtons";

export default function LoginPage(props: {
  login: (onError: (error: string) => void) => void;
}) {
  const [error, setError] = useState("");

  return (
    <div className={styles.container}>
      <div className={styles.title}>Violet</div>

      <CustomRaisedButton
        label="התחבר"
        onClick={() => props.login(setError)}
        variant="contained"
      />

      <div>{error}</div>
    </div>
  );
}
