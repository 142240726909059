import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import SideMenu from "../../Components/SideMenu";
import Screen from "../../Components/Screen";
import CourseDetailsSection from "./PublicCoursePage/Sections/CourseDetailsSection";
import CourseLecturesSection from "./PublicCoursePage/Sections/CourseLecturesSection";
import SaveIcon from "@mui/icons-material/Save";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import { openDialog, openSnackbar } from "../../Store/Appearance/Actions";
import { Path } from "../Path";
import { addNewPublicCourseIndex } from "../../Store/InitialData/Actions";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import { PublicCourseLectureField } from "../../Store/Appearance/RequiredFields/FieldNames";
import { getElementInArrayMissingFields } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import { PublicCourse, PublicCourseLecture } from "../../generated/Api";

const PUBLIC_COURSE_LECTURE_REQUIRED_FIELDS = [
  PublicCourseLectureField.date,
  PublicCourseLectureField.topic,
  PublicCourseLectureField.startTime,
  PublicCourseLectureField.endTime,
  PublicCourseLectureField.price,
];

export default function EditPublicCoursePage() {
  const { courseId } = useParams<{ courseId?: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { callApi, isLoading } = useVioletApi();

  const [publicCourse, setPublicCourse] = useState<PublicCourse>(
    getEmptyPublicCourse(),
  );
  const [participantCount, setParticipantCount] = useState<{
    [lectureId: string]: number;
  }>({});
  useEffect(() => {
    if (!courseId) {
      setPublicCourse(getEmptyPublicCourse());
      setParticipantCount({});
      return;
    }

    callApi((api) =>
      api.publicCourses
        .getPublicCourse({ publicCourseId: courseId })
        .then((res) => {
          if (res?.data?.publicCourse) {
            setPublicCourse(res.data.publicCourse);
            const participantCount: {
              [lectureId: string]: number;
            } = {};
            res.data.lecturesSummary.map(
              (lectureSummary) =>
                (participantCount[lectureSummary.lectureId] =
                  lectureSummary.participantCount),
            );
            setParticipantCount(participantCount);
          } else {
            setPublicCourse(getEmptyPublicCourse());
            setParticipantCount({});
          }
        }),
    );
  }, [courseId, callApi]);

  const onSave = async () => {
    const missingFields = getElementInArrayMissingFields(
      publicCourse.publicCourseLectures,
      PUBLIC_COURSE_LECTURE_REQUIRED_FIELDS,
    );
    if (missingFields.length > 0) {
      dispatch(
        openDialog("יש למלא את כל השדות החסרים", "כדי לשמור את הרצאות הקורס"),
      );
      return;
    }

    if (!courseId) {
      callApi((api) =>
        api.publicCourses.createPublicCourse(publicCourse).then((result) => {
          if (result?.data) {
            dispatch(
              openSnackbar(`קורס ${result.data.courseName} נוצר בהצלחה`),
            );
            navigate(Path.EditPublicCourse + "/" + result.data.id);
            dispatch(
              addNewPublicCourseIndex(result.data.id, result.data.courseName!),
            );
          }
        }),
      );
    } else {
      callApi((api) =>
        api.publicCourses.updatePublicCourse(publicCourse).then((result) => {
          if (result?.data) {
            setPublicCourse(result.data);
            dispatch(
              openSnackbar(`קורס ${result.data.courseName} עודכן בהצלחה`),
            );
          }
        }),
      );
    }
  };

  const title = !!publicCourse.id
    ? "קורס ציבורי: " + publicCourse.courseName
    : "קורס ציבורי חדש";

  const sideMenu = (
    <SideMenu title={title}>
      <CustomRaisedButton
        onClick={onSave}
        label="שמור"
        icon={<SaveIcon />}
        variant="contained"
        fullWidth
      />
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu} isLoading={!publicCourse?.id && isLoading}>
      <CourseDetailsSection
        publicCourse={publicCourse}
        setPublicCourse={setPublicCourse}
      />
      <CourseLecturesSection
        publicCourse={publicCourse}
        participantCount={participantCount}
        setPublicCourse={setPublicCourse}
        requiredFields={PUBLIC_COURSE_LECTURE_REQUIRED_FIELDS}
      />
    </Screen>
  );
}

const getEmptyPublicCourse = () =>
  ({
    publicCourseLectures: [] as PublicCourseLecture[],
  }) as PublicCourse;
