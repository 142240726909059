import { Size } from "../../../../Util/Constants/Size";
import { createOptions } from "../../../../Components/CustomComponents/IOption";
import _ from "lodash";
import { paymentConditionsSuggestions } from "../../../../Util/Constants/PaymentCondition";
import CustomAutoComplete from "../../../../Components/CustomComponents/CustomAutoComplete";
import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import CustomCheckbox from "../../../../Components/CustomComponents/CustomCheckbox";
import {
  getFieldLabel,
  OrganizationField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import SelectField from "../../../../Components/SelectField";
import CustomTextField from "../../../../Components/CustomComponents/CustomTextField";
import styles from "./EditableOrganizationSection.module.css";
import { Organization } from "../../../../generated/Api";

const REFERRAL_WAYS = [
  "אתר האינטרנט שלנו",
  "הופנו מלקוח אחר",
  "המלצה מעובד אחר בחברה",
  "הפניה מיועץ",
  "השתתפו בעבר בהרצאה",
  "חיפוש בגוגל",
  "כנס האיכות הישראלי",
  "לקוח ותיק",
  "פרסום אחר",
  "פרסום בלינקדאין",
  "פרסום במייל",
  "פרסום בפייסבוק",
  "קבוצת וואטסאפ",
  "אחר",
];

interface EditableOrganizationSectionPropsBase {
  organization: Organization;
  isDuplicateOrganizationId: boolean;
  children?: React.ReactNode;
  requiredFields: OrganizationField[];
  onOrganizationChange: (
    field: OrganizationField,
  ) => (value: string | boolean) => void;
}

export default function EditableOrganizationSection(
  props: EditableOrganizationSectionPropsBase,
) {
  const org = props.organization;
  return (
    <CustomSection title="פרטי הארגון">
      <div className={styles.section}>
        <div className={styles.row}>
          <CustomTextField
            field={OrganizationField.name}
            valueObject={org}
            onChange={props.onOrganizationChange(OrganizationField.name)}
            requiredFields={props.requiredFields}
            size={Size.X2L}
          />
          <CustomTextField
            field={OrganizationField.companyId}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(OrganizationField.companyId)}
            size={Size.M}
            error={props.isDuplicateOrganizationId}
          />
          <CustomAutoComplete
            field={OrganizationField.paymentConditions}
            valueObject={org}
            requiredFields={props.requiredFields}
            onTextChange={props.onOrganizationChange(
              OrganizationField.paymentConditions,
            )}
            suggestions={paymentConditionsSuggestions}
            size={Size.XL}
          />
          <SelectField
            label={getFieldLabel(OrganizationField.referralWay)}
            value={org.referralWay}
            onChange={props.onOrganizationChange(OrganizationField.referralWay)}
            error={
              !org.referralWay &&
              props.requiredFields &&
              _.includes(props.requiredFields, OrganizationField.referralWay)
            }
            options={createOptions(REFERRAL_WAYS)}
            size={Size.XL}
          />
          <CustomTextField
            field={OrganizationField.referralWayDetails}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(
              OrganizationField.referralWayDetails,
            )}
            size={Size.X2L}
          />
          <CustomTextField
            field={OrganizationField.externalInvoiceHandler}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(
              OrganizationField.externalInvoiceHandler,
            )}
          />
          <CustomCheckbox
            field={OrganizationField.internalOrderIdRequired}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(
              OrganizationField.internalOrderIdRequired,
            )}
          />
        </div>
        <div className={styles.row}>
          <CustomTextField
            field={OrganizationField.address}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(OrganizationField.address)}
          />
          <CustomTextField
            field={OrganizationField.city}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(OrganizationField.city)}
            size={Size.M}
          />
          <CustomTextField
            field={OrganizationField.postalCode}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(OrganizationField.postalCode)}
            size={Size.M}
          />
          <CustomTextField
            field={OrganizationField.notes}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(OrganizationField.notes)}
            size={Size.X3L}
          />
        </div>
      </div>
      {props.children}
    </CustomSection>
  );
}
