import { useLocation, useNavigate } from "react-router";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SearchIcon from "@mui/icons-material/Search";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LaptopIcon from "@mui/icons-material/Laptop";
import ArticleIcon from "@mui/icons-material/Article";
// import UploadIcon from "@mui/icons-material/Upload";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import PieChartIcon from "@mui/icons-material/PieChart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UploadIcon from "@mui/icons-material/Upload";
import CurrencyYuanIcon from "@mui/icons-material/CurrencyYuan";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaymentIcon from "@mui/icons-material/Payment";
import ListItemText from "@mui/material/ListItemText";
import styles from "./MenuItems.module.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import { Path } from "../Pages/Path";
import classNames from "classnames";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { closeDialog, openDialog } from "../Store/Appearance/Actions";
import { CustomFlatButton } from "../Components/CustomComponents/CustomButtons";
import MobileViewContext from "./MobileViewContext";
import { useContext } from "react";
import { getAlertsCount } from "../Store/InitialData/Selectors";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import { clearCacheData } from "../LocalCache";
import { signOut } from "../firebase/Authentication";

export default function MenuItems(props: { onItemClick?: () => void }) {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useContext(MobileViewContext);

  const alertsCount = useSelector(getAlertsCount);

  return (
    <>
      {mobileView && (
        <MenuItem
          label={mobileView ? "עבור לתצוגה מלאה" : "עבור לתצוגת נייד"}
          Icon={mobileView ? LaptopIcon : PhoneAndroidIcon}
          onItemClick={() => setMobileView(!mobileView)}
        />
      )}
      <MenuItem
        label="חיפוש"
        path={Path.Search}
        Icon={SearchIcon}
        onItemClick={props.onItemClick}
      />

      <MenuItem
        label="התראות"
        onClick={() => navigate(Path.ActionRequired)}
        Icon={() => (
          <Badge hidden badgeContent={alertsCount} color="error">
            <NotificationsIcon className={styles.icon} />
          </Badge>
        )}
        onItemClick={props.onItemClick}
      />

      <MenuTitle label="הזמנות" />
      <MenuItem
        label="הזמנה חדשה"
        path={Path.NewOrder}
        Icon={AddIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="כל ההזמנות"
        path={Path.AllOrders}
        Icon={ArticleIcon}
        onItemClick={props.onItemClick}
      />

      <MenuTitle label="קורסים ציבוריים" />
      <MenuItem
        label="קורס ציבורי חדש"
        path={Path.EditPublicCourse}
        Icon={GroupAddIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="כל הקורסים הציבוריים"
        path={Path.AllPublicCourses}
        Icon={GroupsIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="הזמנות פתוחות"
        path={Path.OpenPublicCourseOrders}
        Icon={Diversity1Icon}
        onItemClick={props.onItemClick}
      />

      <MenuTitle label="דוחות" />
      <MenuItem
        label="הרצאות עתידיות"
        path={Path.FutureLectures}
        Icon={CheckCircleIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="צבר הזמנות"
        path={Path.ExpectedIncome}
        Icon={PaymentIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="ממתינות לתשלום"
        path={Path.WaitingPayment}
        Icon={AttachMoneyIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="הרצאות של ירון"
        path={Path.Yaron}
        Icon={CurrencyYuanIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="איך הגיעו אלינו"
        path={Path.Bi}
        Icon={PieChartIcon}
        onItemClick={props.onItemClick}
      />

      <MenuTitle label="כלים" />
      <MenuItem
        label="העלאת קבצים"
        // path={Path.UploadFiles}
        Icon={UploadIcon}
        onItemClick={() =>
          window.open("https://violet.c-point.co.il/#/uploadFiles")
        }
      />
      <MenuItem
        label="תבניות הצעות מחיר"
        path={Path.OfferTemplates}
        Icon={BookmarkBorderIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="ניהול מתקדם"
        path={Path.Admin}
        Icon={SettingsIcon}
        onItemClick={props.onItemClick}
      />
      <MenuItem
        label="התנתקות"
        Icon={LogoutIcon}
        onClick={() => {
          clearCacheData();
          signOut();
        }}
        onItemClick={props.onItemClick}
      />
    </>
  );
}

function MenuTitle(props: { label: string }) {
  return <div className={styles.menuTitle}>{props.label}</div>;
}

function MenuItem(props: {
  label: string;
  path?: Path;
  Icon: OverridableComponent<SvgIconTypeMap>;
  onClick?: () => void;
  onItemClick?: () => void;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const orderHasUnsavedChanges = false;

  const onClick = () => {
    if (!orderHasUnsavedChanges) {
      onClickApproved();
      return;
    }

    dispatch(
      openDialog(
        "ההזמנה מכילה פרטים שטרם נשמרו",
        "האם אתה בטוח שברצונך להמשיך?",
        [
          <CustomFlatButton
            key="כן, המשך"
            label="כן, המשך"
            onClick={onClickApproved}
          />,
          <CustomFlatButton
            key="לא, חזור"
            label="לא, חזור"
            onClick={() => dispatch(closeDialog())}
          />,
        ],
      ),
    );
  };

  const onClickApproved = () => {
    if (props.onClick) {
      props.onClick();
    } else if (props.path) {
      navigate(props.path);
    }
    props.onItemClick && props.onItemClick();
    dispatch(closeDialog());
  };

  const selected = props.path === location.pathname;

  return (
    <ListItem
      disablePadding
      className={classNames({
        [styles.listItem]: true,
        [styles.selectedListItem]: selected,
      })}
      onClick={onClick}
    >
      <ListItemButton>
        <ListItemIcon className={styles.icon}>
          <props.Icon className={styles.icon} />
        </ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItemButton>
    </ListItem>
  );
}
