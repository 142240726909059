import AutoComplete, { ISuggestion } from "../../Components/AutoComplete";
import { useEffect, useState } from "react";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import { useSelector } from "react-redux";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import { getOrganizationsIndex } from "../../Store/InitialData/Selectors";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import styles from "./Admin.module.scss";
import OrganizationsOrderTable from "../OrganizationPage/OrganizationsOrdersTable";
import { OrganizationOrderSummary } from "../../generated/Api";

export function DeleteOrganizationForm() {
  const organizationsIndex = useSelector(getOrganizationsIndex);
  const { callApi } = useVioletApi();

  const [searchedOrganization, setSearchedOrganization] = useState("");
  const [organizationId, setOrganizationId] = useState<string | undefined>();
  const [organizationOrdersSummary, setOrganizationOrdersSummary] = useState<
    OrganizationOrderSummary[]
  >([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const organizationSuggestions = organizationsIndex.map<ISuggestion>((i) => ({
    key: i.id + "",
    label: i.organizationName,
  }));

  useEffect(() => {
    if (!organizationId) {
      return;
    }
    callApi((api) =>
      api.organizations.getOrganization({ organizationId }).then((res) => {
        if (res.data) {
          setOrganizationOrdersSummary(res.data.organizationOrdersSummary);
          return;
        }

        setOrganizationOrdersSummary([]);
      }),
    );
  }, [organizationId, callApi]);

  const deleteOrganization = () => {
    setSuccess(false);
    setError("");
    if (!organizationId || organizationOrdersSummary.length > 0) {
      setError("קלט שגוי");
      return;
    }
    callApi((api) =>
      api.organizations
        .deleteOrganization({
          organizationId,
        })
        .then((res) => {
          setSuccess(res.data.success);
          setError(res.data.errorMessage || "");
          if (res.data.success) {
            setSearchedOrganization("");
            setOrganizationId(undefined);
            setOrganizationOrdersSummary([]);
          }
        }),
    );
  };

  return (
    <CustomSection title="מחיקת ארגון">
      <div className={styles.column}>
        <div className={styles.row}>
          <AutoComplete
            title="ארגון"
            onSuggestionSelected={(suggestion) => {
              const id = suggestion?.key || "-1";
              setOrganizationId(id);
            }}
            suggestions={organizationSuggestions}
            textFieldValue={searchedOrganization}
            onTextChange={setSearchedOrganization}
            width={300}
          />

          <CustomRaisedButton
            label="מחק ארגון"
            onClick={deleteOrganization}
            variant={"contained"}
            disabled={!organizationId || organizationOrdersSummary.length > 0}
          />
        </div>
        {organizationId !== undefined &&
          organizationOrdersSummary.length !== 0 && (
            <OrganizationsOrderTable
              organizationOrdersSummary={organizationOrdersSummary}
            />
          )}
        <div className={styles.message}>
          <span className={styles.error}>{error}</span>
          {success && "הארגון נמחק בהצלחה"}
        </div>
      </div>
    </CustomSection>
  );
}
