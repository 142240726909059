export enum Path {
  Organization = "/org",
  Home = "/",
  FutureLectures = "/futureLectures",
  ExpectedIncome = "/expectedIncome",
  WaitingPayment = "/payment",
  AllOrders = "/allOrders",
  AllPublicCourses = "/allPublicCourses",
  NewOrder = "/newOrder",
  NewOrderForOrg = "/newOrderForOrg",
  NewOrganization = "/newOrganization",
  Order = "/order",
  EditPublicCourse = "/editPublicCourse",
  PublicCourseSummary = "/publicCourseSummary",
  OpenPublicCourseOrders = "/openPublicCourseOrders",
  PublicCourseAttendance = "/publicCourseAttendance",
  PublicCourseTestScores = "/publicCourseTestScores",
  ActionRequired = "/actionRequired",
  Bi = "/bi",
  Yaron = "/yaron",
  OfferTemplates = "/offerTemplates",
  UploadFiles = "/uploadFiles",
  Search = "/search",
  Phone = "/phone",
  Admin = "/admin",
}

export function getOrderPath(orderId: string) {
  return Path.Order + "/" + orderId;
}

export function getOrganizationPath(organizationid: string) {
  return Path.Organization + "/" + organizationid;
}

export function getPublicCourseSummaryPath(courseId: string) {
  return Path.PublicCourseSummary + "/" + courseId;
}

export function getSearchPath(q: string) {
  return Path.Search + "/" + encodeURI(q);
}
