import OrderStatusStepper from "./OrderStatusStepper";
import styles from "./NewOrderForOrganizationPage.module.css";
import { useNavigate, useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import Screen from "../../Components/Screen";
import MobileViewContext from "../../Navigation/MobileViewContext";
import OrganizationSection from "./Sections/OrganizationSection/OrganizationSection";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../Store/Appearance/Actions";
import { Path } from "../Path";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import AutoComplete, { ISuggestion } from "../../Components/AutoComplete";
import { getPublicCoursesIndex } from "../../Store/InitialData/Selectors";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { addNewOrderIndex } from "../../Store/InitialData/Actions";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import OrganizationsOrdersTable from "../OrganizationPage/OrganizationsOrdersTable";
import {
  CreateOrderRequest,
  Organization,
  OrganizationOrderSummary,
} from "../../generated/Api";

export default function NewOrderForOrganizationPage() {
  const { organizationId } = useParams();
  const [mobileView] = useContext(MobileViewContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { callApi, isLoading } = useVioletApi();
  const [searchedPublicCourse, setSearchedPublicCourse] = useState("");
  const publicCoursesIndex = useSelector(getPublicCoursesIndex);
  const publicCourseSuggestions = publicCoursesIndex.map<ISuggestion>(
    (index) => ({
      key: index.id + "",
      label: index.courseName,
    }),
  );

  const [organization, setOrganization] = useState({} as Organization);
  const [organizationOrdersSummary, setOrganizationOrdersSummary] = useState<
    OrganizationOrderSummary[]
  >([]);

  useEffect(() => {
    const isValidOrganizationId = !!organizationId;
    if (!isValidOrganizationId) {
      setOrganization({} as Organization);
      return;
    }

    callApi((api) =>
      api.organizations
        .getOrganization({
          organizationId,
        })
        .then((res) => {
          if (res.data?.organization) {
            setOrganization(res.data.organization);
            setOrganizationOrdersSummary(res.data.organizationOrdersSummary);
          } else {
            setOrganization({} as Organization);
            setOrganizationOrdersSummary([]);
          }
        }),
    );
  }, [organizationId, callApi]);

  const onSaveNewOrder = async (
    isPublicCourseOrder: boolean,
    publicCourseId?: string,
  ) => {
    if (!organizationId) {
      return;
    }
    const request: CreateOrderRequest = {
      organizationId,
      isPublicCourseOrder,
    };
    if (!!publicCourseId) {
      request.publicCourseId = publicCourseId;
    }
    callApi((api) =>
      api.orders.createOrder(request).then((res) => {
        const orderId = res?.data.id!;
        navigate(Path.Order + "/" + orderId);
        dispatch(openSnackbar(`הזמנה מספר ${orderId} נשמרה בהצלחה`));
        dispatch(addNewOrderIndex(orderId, organizationId, organization.name));
      }),
    );
  };

  return (
    <Screen
      // Screen is loading when creating a new order (and organization was already loaded).
      isLoading={!!organization.name && isLoading}
    >
      <div className={mobileView ? "" : styles.content}>
        <OrderStatusStepper />

        <div>
          <OrganizationSection
            organization={organization}
            isDuplicateOrganizationId={false}
          />
          <CustomSection title="סוג הזמנה">
            <div className={styles.organizationSelectorContainer}>
              <div className={styles.selectorOption}>
                <CustomRaisedButton
                  icon={<CorporateFareIcon />}
                  variant="outlined"
                  label="קורס פנים ארגוני"
                  onClick={() => onSaveNewOrder(false)}
                />
              </div>

              <div className={styles.divider} />

              <div className={styles.selectorOption}>
                <AutoComplete
                  hintText="חיפוש קורס ציבורי"
                  suggestions={publicCourseSuggestions}
                  textFieldValue={searchedPublicCourse}
                  onTextChange={setSearchedPublicCourse}
                  onSuggestionSelected={(selected) =>
                    onSaveNewOrder(true, selected?.key)
                  }
                  fullWidth
                />
              </div>
            </div>
          </CustomSection>

          <OrganizationsOrdersTable
            organizationOrdersSummary={organizationOrdersSummary}
          />
        </div>
      </div>
    </Screen>
  );
}
